<template>
  <div class="Workers">
    <v-row class="Workers__filter-row">
      <v-col>
        <v-btn text small color="primary" @click="isDialogOpen = true">
          + Add Worker
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      class="Workers__data-table elevation-0"
      outlined
      item-key="id"
      :item-class="() => 'Workers__data-table-row'"
      :items="workers"
      :headers="headers"
      :loading="fetchWorkersLoading || deleteWorkerLoading"
      @click:row="handleGoToWorker"
    >
      <template v-slot:[`item.type`]="{ item }">
        <v-chip small>
          <strong>
            {{ item.type?.toUpperCase() }}
          </strong>
        </v-chip>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn icon color="error" @click.stop="triggerDelete(item)">
          <v-icon small> mdi-delete </v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <action-prompt
      v-model="showDeleteWorkerModal"
      v-if="showDeleteWorkerModal"
      action-text="Delete"
      :action="removeWorker"
      :loading="deleteWorkerLoading"
      header="Delete Worker"
      :body="deleteMessageBody"
      action-type="error"
    />

    <create-worker :dialog="isDialogOpen" @close="isDialogOpen = false" />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import ActionPrompt from '@/components/modals/action-prompt/action-prompt.vue';
import CreateWorker from '@/components/modals/create-worker/create-worker.vue';

const { mapGetters: workersGetters, mapActions: workersActions } =
  createNamespacedHelpers('workers');
const { mapGetters: customerGetters } = createNamespacedHelpers('customer');

export default {
  name: 'BrowserWorkers',
  data() {
    return {
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Workflows Using Worker', value: 'count' },
        { text: 'Worker Type', value: 'type' },
        { text: '', value: 'actions', sortable: false, align: 'end' },
      ],
      workerCount: null,
      workerId: null,
      showDeleteWorkerModal: false,
      isDialogOpen: false,
    };
  },
  components: {
    ActionPrompt,
    CreateWorker,
  },
  watch: {
    'selectedCustomer.customer_id'() {
      this.fetchWorkers();
    },
  },
  computed: {
    ...workersGetters({
      workers: 'WORKERS',
      fetchWorkersLoading: 'FETCH_WORKERS_LOADING',
      deleteWorkerLoading: 'DELETE_WORKER_LOADING',
    }),
    ...customerGetters({
      selectedCustomer: 'SELECTED_CUSTOMER',
    }),
    deleteMessageBody() {
      const lineOne = 'Are you sure you want to delete this worker?';
      const lineTwo = `There are ${this.workerCount} workflows using this worker.`;
      let message = lineOne;
      if (this.workerCount) message += `\n\n${lineTwo}`;
      return message;
    },
  },
  mounted() {
    this.fetchWorkers();
  },
  methods: {
    ...workersActions(['fetchWorkers', 'deleteWorker']),
    triggerDelete({ id, count }) {
      this.workerCount = count;
      this.workerId = id;
      this.showDeleteWorkerModal = true;
    },
    async removeWorker() {
      await this.deleteWorker(this.workerId);
      this.workerCount = null;
      this.workerId = null;
    },
    handleGoToWorker(item) {
      this.$router.push({ path: `/workers/${item.id}` });
    },
  },
};
</script>

<style lang="scss" scoped>
@import './workers';
</style>
