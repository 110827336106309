<template>
  <v-dialog :value="dialog" persistent max-width="400">
    <v-card>
      <v-card-title class="text-h5"> Create Worker </v-card-title>

      <div class="px-5 my-5">
        <v-text-field
          label="Worker Name"
          placeholder="Worker Name"
          outlined
          v-model="workerName"
        ></v-text-field>
        <v-select
          outlined
          label="Worker Type"
          :items="workerTypes"
          item-text="name"
          item-value="value"
          :value="workerType"
          @change="setWorkerType"
        ></v-select>
      </div>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('close')"> Cancel </v-btn>
        <v-btn color="blue darken-1" text @click="addWorker"> Create </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapActions: workersActions } = createNamespacedHelpers('workers');

export default {
  name: 'CreateWorkers',
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      workerName: '',
      workerType: 'playwright',
      workerTypes: [
        { name: 'Playwright', value: 'playwright' },
        { name: 'Python', value: 'python' },
      ],
    };
  },
  methods: {
    ...workersActions(['createWorker']),
    setWorkerType(type) {
      this.workerType = type?.toLowerCase();
    },
    addWorker() {
      this.createWorker({
        name: this.workerName,
        type: this.workerType,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import './create-worker';
</style>
